/* Umum */
body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

/* Container utama */
.body-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

/* Konten utama */
.col-10 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
}

h2 {
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 20px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Tabel */
.table {
  margin-top: 20px;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}

.table th {
  background-color: #007bff;
  color: #fff;
}

.table img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 4px;
}

/* Tombol */
.btn {
  border-radius: 20px;
  padding: 8px 20px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-danger {
  background-color: #dc3545;
  border: none;
}

.btn-primary:hover, .btn-danger:hover {
  opacity: 0.8;
}

/* Modal */
.modal-header {
  background-color: #007bff;
  color: white;
}

.modal-title {
  color: white;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-body img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-top: 10px;
}

/* Form */
.form-group {
  margin-bottom: 15px;
}

.form-control {
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ced4da;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Action buttons */
.table .action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;  /* Jarak antara tombol */
}

.table .action-buttons .btn {
  margin: 0 5px;  /* Margin horizontal untuk tombol */
  padding: 5px 10px;  /* Padding untuk ukuran tombol yang lebih kecil */
}
