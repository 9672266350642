.body-flex {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

.flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-title {
  color: #495057;
}

.card {
  margin: 10px 0;
}

.user-table {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-table .table-dark {
  background-color: #343a40;
  color: white;
}

.user-table th,
.user-table td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
}

.user-table th {
  background-color: #6c757d;
  color: white;
}

.user-table tbody tr:hover {
  background-color: #f1f1f1;
}

.btn-edit {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-edit:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.btn-delete {
  background-color: #dc3545;
  border-color: #dc3545;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-delete:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.modal-content {
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-weight: bold;
  color: #343a40;
}

.form-label {
  color: #495057;
}

.form-control {
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
