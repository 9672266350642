.add_new {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;

  .new_page {
    .new_page_main {
      padding: 20px 25px;
      .new_page_content {
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        display: grid;
        grid-template-columns: 37% 60%;
        justify-content: center;
        gap: 10;
        padding: 20px;
        border-radius: 4px;

        .image {
          flex: 4;

          .add_new_user {
            color: rgba(128, 128, 128, 0.603);
            font-size: 25px;
            margin-right: auto;
          }

          img {
            height: 150px;
            width: 150px;
            background-color: rgba(128, 128, 128, 0.288);
            object-fit: cover;
            margin-top: 20px;
            border-radius: 50%;
            margin-left: 30%;
          }
        }

        .form {
          flex: 8;
          display: grid;
          grid-template-columns: 45% 45%;
          justify-content: space-around;
          grid-gap: 15px;
          position: relative;

          .form_inp {
            label {
              display: flex;
              align-items: center;
            }
            .file_icon {
              cursor: pointer;
              margin-left: 10px;
              font-size: 30px;
            }
          }

          .submit_btn {
            margin-top: 15px;
            display: block;
            width: 200px;
            padding: 9px 15px;
            border: none;
            background: none;
            background-color: #7451f8;
            border: 1px solid #7451f8;
            color: white;
            font-size: 17px;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background-color: white;
              color: #7451f8;
              border: 1px solid #7451f8;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .add_new {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;

    .home_sidebar {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .add_new {
    .new_page {
      .new_page_main {
        .new_page_content {
          display: grid;
          grid-template-columns: 95%;
          justify-content: center;
          gap: 40px;
        }
      }
    }
  }
}
