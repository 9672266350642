.body-flex {
  display: flex;
  justify-content: center;
  width: 100%;
}

.flex {
  display: flex;
  width: 100%;
}

.col-10 {
  margin: auto;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.bold-header th {
  font-weight: bold;
}

/* CSS untuk tombol tambah artikel */
.add-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/* Media queries untuk tampilan responsif */
@media screen and (max-width: 992px) {
  .body-flex {
    flex-direction: column;
    align-items: center;
  }
  
  .flex {
    flex-direction: column;
  }
  
  .col-10 {
    width: 100%;
  }
}
