/* General Styles */
.header {
  background: linear-gradient(45deg, #1c54b6, #161616);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  padding: 10px 0;
}

.sticky-header {
  background: linear-gradient(45deg, #1b54b7, #161616);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Navigation Wrapper */
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 50px;
  margin-right: 10px;
}

.logo h1 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

/* Navigation Menu */
.navigation {
  display: flex;
}

.menu {
  display: flex;
  list-style: none;
}

.menu li {
  margin-left: 20px;
}

.menu li a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  transition: color 0.3s ease;
}

.menu li a:hover {
  color: #ffd700;
}

/* Icons */
.nav-icons {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #ffd700;
}

/* User Icon Popup */
.user-icon {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
}

.user-icon-img {
  width: 30px;
  height: 30px;
}

.popup {
  position: absolute;
  top: 30px; /* Adjusted from 40px to 30px to move closer to the profile icon */
  right: 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.popup button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 16px;
}

.popup button:hover {
  color: #007bff;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
}

.notification-popup {
  margin: 30px 60px 0 0;
  position: absolute;
  top: 50px;
  right: 40px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notification-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-popup li {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.notification-popup li:last-child {
  border-bottom: none;
}

.notification-popup p {
  margin: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .navigation {
    display: none;
  }

  .active-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .menu {
    flex-direction: column;
  }

  .menu li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .mobile-menu {
    display: block;
  }
}
