/* OrderPage.css */

.body-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
}

.flex {
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.col-10 {
  width: 100%;
  padding: 20px;
}

.order-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.ctable {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.ctable th,
.ctable td {
  padding: 12px;
  text-align: left;
}

.ctable th {
  background-color: #4B5320;
  color: white;
}

.ctable-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.ctable-striped tbody tr:hover {
  background-color: #ddd;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.accept-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #218838;
}

.reject-button {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.reject-button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .order-title {
    font-size: 1.5rem;
  }

  .action-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .accept-button,
  .reject-button {
    width: 100%;
    padding: 10px;
  }
}
