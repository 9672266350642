.details {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;

  .detail_page_main {
    .user_info {
      display: grid;
      grid-template-columns: 22% 70%;
      // align-items: center;
      gap: 30px;
      padding: 30px 25px;

      .user_detail {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        text-align: center;
        padding: 20px;
        border-radius: 3px;
        flex: 3;
        height: fit-content;

        .user_image {
          height: 130px;
          width: 130px;
          border-radius: 50%;
          object-fit: cover;
        }

        .user_detailss {
          margin-top: 10px;

          p {
            margin-bottom: 10px;
          }

          .name {
            font-size: 30px;
          }
        }
      }

      .user_chart {
        flex: 9;
      }
    }

    .table {
      padding: 20px 25px;

      .title {
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .details {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    .home_sidebar {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .details {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;

    .detail_page_main {
      .user_info {
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        gap: 30px;
      }
    }
  }
}
