/* checkout.css */
body {
  font-family: 'Arial', sans-serif;
}

.checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.checkout-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.checkout-section {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-subtitle {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn-danger:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  animation: slide-down 0.5s ease-out;
}

.modal-content {
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.5em;
}

.close {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.address-list {
  list-style: none;
  padding: 0;
}

.address-item {
  background: #f1f1f1;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.address-item:hover {
  background: #e9e9e9;
}

.address-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.payment-summary {
  border-top: 2px solid #ddd;
  padding-top: 20px;
}

@keyframes slide-down {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.checkout-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkout-item img {
  border-radius: 10px;
  transition: transform 0.3s;
}

.checkout-item img:hover {
  transform: scale(1.1);
}

.checkout-item-info {
  flex: 1;
}
