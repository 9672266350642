.blog_details {
  display: grid;
  grid-template-columns: 17% 83%;
  justify-content: center;

  .detail_page_main {
    .blog_detailss {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      h1 {
        color: rgb(43, 42, 42);
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .blog_detail_img {
        object-fit: cover;
        border-radius: 2px;
        width: 100%;
        height: 500px;
        border-radius: 3px;
      }

      .blog_detail_tv {
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;

        p {
          display: flex;
          align-items: center;
          color: rgb(29, 48, 77);
        }
      }

      .blog_detail_txt {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 1px;
        color: rgb(25, 40, 63);
      }

      .tags {
        margin-top: 30px;
        margin-bottom: 50px;

        h3 {
          margin-bottom: 10px;
        }

        .blog_detail_tag {
          padding: 5px 10px;
          font-size: 15px;
          font-weight: bold;
          margin: 5px 5px;
          border: 1px solid #2650b1;
          border-radius: 4px;
          color: #2f509c;
          transition: all 0.3s ease;
          cursor: pointer;
        }
      }
    }
  }
}
