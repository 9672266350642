/* FooterPenjual.css */

/* General Footer Styles */
.footer-penjual {
    background: linear-gradient(45deg, #161616, #333);
    padding: 30px 0;
    color: #fff;
    transition: background 0.3s ease-in-out;
  }
  
  .footer-penjual:hover {
    background: linear-gradient(45deg, #333, #161616);
  }
  
  /* Logo Section */
  .logo h1 {
    font-size: 28px;
    font-weight: bold;
    color: #ffd700;
  }
  
  /* Footer Text */
  .footer__text {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
  }
  
  /* Quick Links Section */
  .footer__quick-links {
    margin-bottom: 20px;
  }
  
  .quick__links-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffd700;
    margin-bottom: 10px;
  }
  
  .footer__quick-links .list-group-item {
    background: none;
    padding-left: 0;
    border: none;
  }
  
  .footer__quick-links .list-group-item a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer__quick-links .list-group-item a:hover {
    color: #ffd700;
  }
  
  /* Contact Section */
  .footer__contact {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer__contact .list-group-item {
    background: none;
    padding-left: 0;
    border: none;
  }
  
  .footer__contact .list-group-item span i {
    color: #ffd700;
  }
  
  .footer__contact .list-group-item p {
    margin-bottom: 0;
    color: #fff;
  }
  
  /* Copyright Section */
  .footer__copyright {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    color: #bbb;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .footer-penjual {
      padding: 30px 20px;
    }
  
    .quick__links-title {
      font-size: 18px;
    }
  
    .footer__copyright {
      font-size: 12px;
    }
  }
  