.search__widget,
.sorting__widget select {
  padding: 7px 15px;
  border: 1px solid #fde4e4;
  border-radius: 5px;
  cursor: pointer;
}
.search__widget input {
  border: none;
}

.search__widget input:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .search__widget {
    width: 100%;
    margin-bottom: 20px;
  }
  .search__widget input {
    width: 100%;
    font-size: 0.8rem;
  }
  .sorting__widget select {
    width: 100% !important;
    font-size: 0.8rem;
  }
}

.product-animation {
  position: fixed;
  z-index: 1000;
  animation: floatProduct 1s ease forwards;
}

@keyframes floatProduct {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px) translateX(200px);
    opacity: 0;
  }
}
