/* Global Styles */
body {
    font-family: Arial, sans-serif;
  }
  
  /* Hero Section */
  .hero__section {
    padding: 100px 0;
    background-color: #ffffff;
  }
  
  .hero__content {
    margin-bottom: 30px;
  }
  
  .hero__subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #556B2F;
    margin-bottom: 20px;
  }
  
  .hero__content h2 {
    font-size: 50px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  
  .hero__content p {
    font-size: 17px;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .buy__button {
    background-color: #556B2F;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .buy__button:hover {
    background-color: #556B2F;
  }
  
  .buy__button a {
    color: #fff;
    text-decoration: none;
  }
  
  .hero__img img {
    max-width: 100%;
    height: auto;
  }
  
  /* Section Titles */
  .section__title {
    font-size: 40px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .section__separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  

  
  .info-section {
    text-align: center;
    padding: 50px 0;
    background-color: #fff;
  }
  
  .section-title {
    font-size: 40px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  
  .info-header {
    background-color: #556B2F;
    color: #fff;
    padding: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .info-header h2 {
    font-size: 24px;
    margin: 0;
  }
  
  .info-buttons {
    margin-top: 20px;
  }
  
  .info-button {
    background-color: #fff;
    color: #556B2F;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .info-button1 {
    background-color: #ff1010;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .info-item {
    text-align: center;
    padding: 20px;
  }
  
  .info-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .info-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .info-item p {
    font-size: 16px;
    color: #666;
  }
  
  .info-section .section-title {
    font-size: 40px;
    font-weight: bold;
    color: #000;
    margin-bottom: 70px;
    text-align: center; /* Centering the title */
  }
  
  /* Product Section */
  .product-section .section_title {
    font-size: 35px;
    font-weight: bold;
    color: #000;
    margin-bottom: 50px;
    text-align: center; /* Centering the title */
  }
  
  .product-carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .product-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    flex: 0 0 70%;
    padding: 0 20px;
  }
  
  .product-carousel::-webkit-scrollbar {
    display: none;
  }
  
  .product-item {
    scroll-snap-align: center;
    flex: 0 0 calc(33.333% - 20px);
    margin-right: 1rem;
    width: 200px; 
  }
  
  .product-card {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 500px;
  }
  
  .product-card img {
    width: 50%;
    height: auto;
    object-fit: cover;
  }
  
  .carousel-control.left, .carousel-control.right {
    background-color: transparent;
    color: #8ae669;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  
  .carousel-control.left {
    left: 10px;
  }
  
  .carousel-control.right {
    right: 10px;
  }
  
  /* Adjust font size for product section title */
  .product-section .section-title {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  .testimonial-section {
    padding: 2rem 0;
    margin-bottom: 90px;
  }
  
  .testimonial-section .section_title {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin-bottom: 70px;
    text-align: center; /* Centering the title */
  }
  
  .testimonial-carousel-container {
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .testimonial-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    flex: 0 0 70%;
    padding: 0 20px;
  }
  
  .testimonial-carousel::-webkit-scrollbar {
    display: none;
  }
  
  .testimonial-item {
    scroll-snap-align: center;
    flex: 0 0 calc(33.333% - 20px);
    margin-right: 1rem;
    width: 200px; 
  }
  
  .testimonial-card {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: auto;
  }
  
  .testimonial-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .carousel-control.left, .carousel-control.right {
    background-color: transparent;
    color: #8ae669;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  
  .carousel-control.left {
    left: 10px;
  }
  
  .carousel-control.right {
    right: 10px;
  }
  
  .artikel-section {
    background-color: #d2d3d1;
  }
  
  .info-header2 {
    text-align: center;
    margin-bottom: 60px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 90%;
    display: flex; /* Menjadikan container flex */
    flex-direction: column; /* Menjadikan anak-anaknya menjadi satu kolom */
  }
  
  .info-header2 .section-title {
    font-size: 40px;
    font-weight: bold;
    color: #212121;
    margin-top: 70px; /* Atur margin atas agar elemen meletakkan dirinya di bagian bawah container */
    margin-bottom: 60px;
    text-align: center; /* Pusatkan judul */
  }
  
  
  
  .service-card {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .service-icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #666; /* Primary Green for icons */
  }
  
  .service-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #333; /* Dark Gray for text */
  }
  
  .service-subtitle {
    font-size: 1rem;
    color: #666; /* Light Gray for subtitles */
  }
  
  .info-section3 {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex; /* Use 'flex' instead of 'fit' */
    flex-direction: column; /* Ensure items are stacked vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    padding: 20px; /* Add some padding for better spacing */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .section-title3 {
    font-size: 40px;
    font-weight: bold;
    color: #212121;
    margin-top: 70px; /* Atur margin atas agar elemen meletakkan dirinya di bagian bawah container */
    margin-bottom: 100px;
    text-align: center; /* Pusatkan judul */
  }
  
  .video-row {
    display: flex;
    justify-content: space-around; /* Space videos evenly */
    flex-wrap: wrap; /* Allow videos to wrap to the next line if needed */
    margin-top: 20px; /* Add some space between the title and videos */
  }
  
  .video-player {
    width: 300px; /* Set the width of each video player */
    height: 200px; /* Set the height of each video player */
    margin: 10px; /* Add some space around each video player */
  }
  