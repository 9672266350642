$icon_color: #053653;
$text_color: #3d3c42;

.sidebar {
  border-right: 1px solid #053653;
  margin-left: 10px;

  .logo {
    cursor: pointer;
    color: $icon_color;
    padding: 19px 20px;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    text-align: center;

    .text_none {
      text-decoration: none;
      color: $icon_color; // Make sure $icon_color is defined somewhere in your styles
      margin-top: 5px; // Maintain the top margin to separate the text from other elements
      display: block; // Ensure the text behaves as a block element
      font-family: 'Arial', sans-serif; // Change the font family to something more professional
      font-size: 1.2em; // Adjust the font size to be more readable
      font-weight: 500; // Make the font weight less blocky
      padding-left: 15px; // Add left padding to move the text slightly to the left
  }
  }  

  .links {
    margin: 15px 10px;
    ul {
      .spann {
        text-transform: uppercase;
        color: gray;
        margin: 10px 0px 5px;
        font-size: 15px;
      }

      li {
        display: flex;
        align-items: center;
        margin-left: 10px;
        color: $text_color;
        padding: 5px 10px;
        font-weight: 450;
        transition: all 0.2s ease;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: all 0.2s ease;

        &:hover {
          background-color: $icon_color;
          color: white;
        }
        &:hover .icon {
          color: white;
        }
        .icon {
          color: $icon_color;
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }

  .colorss {
    display: flex;
    margin: 20px 10px;
    .color_option {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #333;
      margin: 5px;
      cursor: pointer;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    .logo {
      padding: 15px 10px;
      font-size: 16px;
    }

    .links {
      margin: 10px;
      ul {
        li {
          padding: 5px 8px;
          font-size: 14px;
        }
      }
    }

    .colorss {
      margin: 15px 10px;
    }
  }
}
