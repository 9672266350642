/* product-details.css */

/* General Styles */
.product-details-section {
  background-color: #f5f5f5;
  padding: 60px 0;
}

.product__main-img img {
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product__main-img img:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.single__product-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.product__title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.product__price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.product__details-table {
  width: 100%;
  margin-top: 20px;
}

.product__details-table th,
.product__details-table td {
  padding: 15px;
  border: 1px solid #ddd;
}

.product__details-table th {
  background-color: #f9f9f9;
  text-align: left;
}

.product__details-table td {
  background-color: #fff;
}

.add-to-cart-btn {
  background-color: #ff5722;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #000000;
}

.loading {
  text-align: center;
  font-size: 2rem;
  color: #ff5722;
  padding: 100px 0;
}
