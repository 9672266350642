.product__container {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.product__item {
  border: none;
  text-align: center;
  padding: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.product__item:hover {
  transform: scale(1.05);
}

.product__img {
  height: 200px;
  overflow: hidden;
}

.product__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product__img:hover img {
  transform: scale(1.1);
}

.product__content {
  padding: 20px;
}

.product__content h5 a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 1.1rem;
  color: #214969;
  text-decoration: none;
}

.product__price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #214969;
}

.addToCart__btn {
  border: none;
  padding: 7px 25px;
  background: #0b0a31;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: background 0.3s ease;
}

.addToCart__btn:hover {
  background: #214969;
}

@media only screen and (max-width: 992px) {
  .product__content h5 a {
    font-size: 1rem;
  }
  .addToCart__btn {
    padding: 5px 15px;
    font-size: 0.8rem;
  }
}

.text-danger {
  color: red;
  font-weight: bold;
}
